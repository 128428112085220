import React, { ReactElement } from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import { AboutPageQuery } from "../../types/gatsby-graphql"

interface Props {
  data: AboutPageQuery
}

export default function About({ data }: Props): ReactElement {
  return (
    <div style={{ color: `teal` }}>
      <Header headerText="About Gatsby." />
      <h1>About {data.site?.siteMetadata?.title}</h1>
      <p>Such wow. Very React.</p>
    </div>
  )
}

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
