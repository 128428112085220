import React, { ReactElement } from "react"

interface Props {
  headerText: string
}

const Header = ({ headerText }: Props): ReactElement => {
  return <h1>{headerText}</h1>
}

export default Header
